import { useEffect, useState } from 'react'

export const useWindowWidth = () => {
  // Initialize state with undefined so that we render nothing on the server.
  const [width, setWidth] = useState(350)

  useEffect(() => {
    // Set the initial width to the client-side window width
    setWidth(window.innerWidth)

    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures this effect only runs once on mount

  return width
}
