import { cx } from '../helpers/helper'

export default function AltLogoBox({
  className,
  onClick,
}: {
  className: string
  onClick?: () => void
}) {
  return (
    <svg
      className={cx('fill-none', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 349 139"
      onClick={onClick ? onClick : () => {}}
    >
      <path d="M348.005 15.5H305.497L306.371 137.889L291.372 137.997L290.497 15.5H247V0.5H348.005V15.5Z" />
      <path d="M0 139L63.7655 1H74.836L78.0908 8.14219L77.7902 8.79277L137.197 139H119.611L68.9412 27.9437L17.6255 139H0Z" />
      <path d="M155.391 2L156.377 138L158.391 137.986V138H246.391V123H171.268L170.391 1.89131L155.391 2Z" />
    </svg>
  )
}
