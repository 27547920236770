import { useEffect, useState } from 'react'

export const useWindowHeight = () => {
  // Initialize state with undefined so that we render nothing on the server.
  const [height, setHeight] = useState(768)

  useEffect(() => {
    // Set the initial width to the client-side window width
    setHeight(window.innerHeight)

    const handleResize = () => {
      setHeight(window.innerHeight)
    }

    window.addEventListener('resizeHeight', handleResize)

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resizeHeight', handleResize)
  }, []) // Empty array ensures this effect only runs once on mount

  return height
}
