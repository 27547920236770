export function cx(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const minifyImage = (img: string, width = 50) => {
  if (img.includes('cdn.shopify') && !img.includes('?')) {
    return `${img}?width=${width}`
  }

  if (img.includes('cdn.shopify') && img.includes('v=')) {
    return `${img}&width=${width}`
  }

  if (img.includes('dailyobjects')) {
    const url = img.split('?')[0]
    return `${url}?tr=w-${width}`
  }

  if (img.includes('static.zara.net')) {
    return img.replace('w/420//', '/w/420/')
  }
  return img
}

export function cleanString(input: string | undefined): string {
  if (!input) return ''
  return input
    .toLowerCase()
    .normalize('NFD')
    .replace(/&/g, ' and ')
    .replace(/ {2}/g, ' ')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/[^a-zA-Z 0-9-]/g, '')
}
