import { track } from '@amplitude/analytics-browser'
import { logEvent } from 'firebase/analytics'
import { getPwaOrNot } from '../helpers/device-helper'
import { APP_VERSION } from './config'
import { analytics } from './firebase-config'

declare global {
  interface Window {
    fbq: any
  }
}

export enum CriticalEvents {
  LOGIN_START = 'login_started',
  LOGIN_SUCCESS = 'login_success',
  SIGN_UP_SUCCESS = 'signup_success',
  SEARCH = 'search',
  PRODUCT_VIEW = 'product_view',
  ADD_TO_CART = 'add_to_cart',
  WISHLIST = 'wishlist',
  ONBOARDING_DONE = 'onboarding_done',
  INSTALL_CLICK = 'install_click',
}

export function logCriticalEvent(eventName: CriticalEvents) {
  switch (eventName) {
    case CriticalEvents.INSTALL_CLICK: {
      logFirebaseEvent(eventName)
      logFacebookEvent(eventName, true)
      break
    }
    case CriticalEvents.LOGIN_START: {
      logFirebaseEvent(eventName)
      logFacebookEvent(eventName, true)
      break
    }
    case CriticalEvents.ONBOARDING_DONE: {
      logFacebookEvent(eventName, true)
      logFirebaseEvent(eventName)
      break
    }
    case CriticalEvents.LOGIN_SUCCESS: {
      logFacebookEvent('Lead')
      logFirebaseEvent('login')
      break
    }
    case CriticalEvents.SIGN_UP_SUCCESS: {
      logFacebookEvent('CompleteRegistration')
      logFirebaseEvent('sign_up')
      break
    }
    case CriticalEvents.SEARCH: {
      logFacebookEvent('Search')
      logFirebaseEvent('search')
      break
    }
    case CriticalEvents.PRODUCT_VIEW: {
      logFacebookEvent('ViewContent')
      logFirebaseEvent('select_item')
      break
    }
    case CriticalEvents.ADD_TO_CART: {
      logFacebookEvent('AddToCart')
      logFirebaseEvent('add_to_cart')
      break
    }
    case CriticalEvents.WISHLIST: {
      logFacebookEvent('AddToWishlist')
      logFirebaseEvent('add_to_wishlist')
      break
    }
  }
}

async function logFirebaseEvent(eventName: string) {
  const cachedAnalytics = await analytics
  if (cachedAnalytics) {
    logEvent(cachedAnalytics, eventName)
  }
}

async function logFacebookEvent(eventName: string, customEvent = false) {
  if (window.fbq) {
    if (customEvent) {
      window.fbq('trackCustom', eventName)
    } else {
      window.fbq('track', eventName)
    }
  }
}

// export async function logEcomEvent(
//   eventName: string,
//   brand: BrandData,
//   products: ProductData[],
//   variant?: ProductVariantData,
//   affiliation?: string,
//   pageId?: string,
// ) {
//   track(eventName, {
//     brand: brand.id,
//     product: products.length == 1 ? products[0].productId : undefined,
//     variant: variant ? variant.colour : undefined,
//     destination: affiliation,
//     pageId,
//   })

//   let value = 0
//   if (variant) {
//     value = parseInt(variant.price)
//   } else {
//     products.forEach((product) => {
//       value = value + parseInt(product.variants[0].price)
//     })
//   }

//   const cachedAnalytics = await analytics
//   if (cachedAnalytics) {
//     const eventParams: EventParams = {
//       item_list_id: brand.id,
//       item_list_name: brand.name,
//       currency: 'INR',
//       value,
//       affiliation,
//       items: products.map((product) => {
//         return {
//           item_id: `${brand.id}/${product.productId}`,
//           item_name: product.name,
//           item_brand: brand.id,
//           item_category: product.categoryId,
//           item_variant: variant ? variant.colour : product.variants[0].colour,
//           price: variant ? variant.price : product.variants[0].price,
//           quantity: 1,
//         }
//       }),
//     }
//     logEvent(cachedAnalytics, eventName, eventParams)
//   }
// }

let isPWAGlobal: boolean | undefined = undefined

export function logAnalyticsEvent(
  name: string,
  properties: Record<string, any> = {},
) {
  if (!isPWAGlobal) {
    isPWAGlobal = getPwaOrNot()
  }
  track(name, { ...properties, versionCode: APP_VERSION, isPWA: isPWAGlobal })
}
