import Link from 'next/link'
import {
  CriticalEvents,
  logAnalyticsEvent,
  logCriticalEvent,
} from '../../../data/analytics'

export function InstallButtonsBox({
  type,
}: {
  type: 'light' | 'black' | 'round' | 'white'
}) {
  return (
    <div className="flex flex-row gap-4 h-[46px]">
      <Link
        href={
          'https://apps.apple.com/in/app/alt-fashion-search/id6468093846?ct=homepage'
        }
        prefetch={false}
      >
        <a
          onClick={() => {
            logAnalyticsEvent('app_store_clicked')
            logCriticalEvent(CriticalEvents.INSTALL_CLICK)
          }}
        >
          <img
            src={`assets/landing-page/app-store-${type}.png`}
            alt="app-store"
            className="h-[46px]"
          />
        </a>
      </Link>
      <Link
        href={
          'https://play.google.com/store/apps/details?id=co.the_alt.alt_app&hl=en_US&utm_source=web&utm_campaign=homepage'
        }
        prefetch={false}
      >
        <a
          onClick={() => {
            logAnalyticsEvent('play_store_clicked')
            logCriticalEvent(CriticalEvents.INSTALL_CLICK)
          }}
        >
          <img
            src={`assets/landing-page/play-store-${type}.png`}
            alt="play-store"
            className="h-[46px]"
          />
        </a>
      </Link>
    </div>
  )
}
