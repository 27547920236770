export function getPwaOrNot() {
  function isInStandaloneMode() {
    return Boolean(
      (window.matchMedia &&
        window.matchMedia('(display-mode: standalone)').matches) ||
        (window.navigator as any)?.standalone, // Fallback for iOS
    )
  }

  let hasBeenInStandaloneMode
  if (isInStandaloneMode()) {
    hasBeenInStandaloneMode = true
    sessionStorage.setItem('is_standalone_mode', 'yes')
  } else {
    hasBeenInStandaloneMode =
      sessionStorage.getItem('is_standalone_mode') === 'yes'
  }
  return hasBeenInStandaloneMode
}

export enum OperatingSystem {
  Android = 'Android',
  iOS = 'iOS',
  WindowsPhone = 'Windows Phone',
  Unknown = 'unknown',
}

export function getMobileOperatingSystem() {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    // return OperatingSystem.WindowsPhone
    return OperatingSystem.Unknown
  }

  if (/android/i.test(userAgent)) {
    return OperatingSystem.Android
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return OperatingSystem.iOS
  }

  return OperatingSystem.Unknown
}
